<template>
  <div>
    <div class="scoring-gauge" v-if="hasData">
      <div :class="wheelClasses">
        <div class="scoring-gauge__wheel-desc">
          <div class="scoring-gauge__wheel-desc__label">{{scoringGaugeWheelLabel}}</div>
          <div class="scoring-gauge__wheel-desc__points">
            <span>{{scoringGaugeWheelPoints}}</span>/100
          </div>
        </div>
        <div class="scoring-gauge__wheel__fullQuadrants">
          <img
            v-if="wheelValue === 0"
            class="fullQuadrant fullQuadrant--0 img-fluid"
            :src="wheelFull0"
          >
          <img
            v-if="wheelValue >= 0 && wheelValue < 10"
            class="fullQuadrant fullQuadrant--1 img-fluid"
            :src="wheelFull1"
          >
          <img
            v-if="wheelValue >= 10 && wheelValue < 20"
            class="fullQuadrant fullQuadrant--2 img-fluid"
            :src="wheelFull2"
          >
          <img
            v-if="wheelValue >= 20 && wheelValue < 30"
            class="fullQuadrant fullQuadrant--3 img-fluid"
            :src="wheelFull3"
          >
          <img
            v-if="wheelValue >= 30 && wheelValue < 40"
            class="fullQuadrant fullQuadrant--4 img-fluid"
            :src="wheelFull4"
          >
          <img
            v-if="wheelValue >= 40 && wheelValue < 50"
            class="fullQuadrant fullQuadrant--5 img-fluid"
            :src="wheelFull5"
          >
          <img
            v-if="wheelValue >= 50 && wheelValue < 60"
            class="fullQuadrant fullQuadrant--6 img-fluid"
            :src="wheelFull6"
          >
          <img
            v-if="wheelValue >= 60 && wheelValue < 70"
            class="fullQuadrant fullQuadrant--7 img-fluid"
            :src="wheelFull7"
          >
          <img
            v-if="wheelValue >= 70 && wheelValue < 80"
            class="fullQuadrant fullQuadrant--8 img-fluid"
            :src="wheelFull8"
          >
          <img
            v-if="wheelValue >= 80 && wheelValue < 90"
            class="fullQuadrant fullQuadrant--9 img-fluid"
            :src="wheelFull9"
          >
          <img
            v-if="wheelValue >= 90 && wheelValue < 99"
            class="fullQuadrant fullQuadrant--10 img-fluid"
            :src="wheelFull10"
          >
          <img
            v-if="wheelValue === 100"
            class="fullQuadrant fullQuadrant--11 img-fluid"
            :src="wheelFull11"
          >
        </div>
      </div>
      <div class="scoring-gauge__points">
        <div class="scoring-gauge__points__row">
          <div class="scoring-gauge__points__row__desc">
            <div class="scoring-gauge__points__row__desc__label">
              {{scoringGaugeCapitalisationLabel}}
            </div>
            <div class="scoring-gauge__points__row__desc__points">
              <span>{{scoringGaugeCapitalisationData}}</span>
            </div>
          </div>
          <div class="scoring-gauge__points__row__score">
            <div :class="scoringGaugeCapitalisationClasses"></div>
          </div>
        </div>
        <div class="scoring-gauge__points__row">
          <div class="scoring-gauge__points__row__desc">
            <div class="scoring-gauge__points__row__desc__label">
              {{scoringGaugeProfitabilityLabel}}
            </div>
            <div class="scoring-gauge__points__row__desc__points">
              <span>{{scoringGaugeProfitabilityData}}</span>
            </div>
          </div>
          <div class="scoring-gauge__points__row__score">
            <div :class="scoringGaugeProfitabilityClasses"></div>
          </div>
        </div>
        <div class="scoring-gauge__points__row">
          <div class="scoring-gauge__points__row__desc">
            <div class="scoring-gauge__points__row__desc__label">
              {{scoringGaugeLiquidityLabel}}
            </div>
            <div class="scoring-gauge__points__row__desc__points">
              <span>{{scoringGaugeLiquidityData}}</span>
            </div>
          </div>
          <div class="scoring-gauge__points__row__score">
            <div :class="scoringGaugeLiquidityClasses"></div>
          </div>
        </div>
        <div class="scoring-gauge__points__row">
          <div class="scoring-gauge__points__row__desc">
            <div class="scoring-gauge__points__row__desc__label">
              {{scoringGaugeSectorLabel}}
            </div>
            <div class="scoring-gauge__points__row__desc__points">
              <span>{{scoringGaugeSectorData}}</span>
            </div>
          </div>
          <div class="scoring-gauge__points__row__score">
            <div :class="scoringGaugeSectorClasses"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <p><em>Impossibile recuperare i dati</em></p>
    </div>
  </div>
</template>

<script>
import { isNotEmpty } from '@/utils/validators';

export default {
  name: 'ScoringGauge',
  components: {},
  props: {
    data: Object,
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      wheelFull0: require('@/assets/img/wheelsFull/wheelsFull_0.png'),
      // eslint-disable-next-line global-require
      wheelFull1: require('@/assets/img/wheelsFull/wheelsFull_1.png'),
      // eslint-disable-next-line global-require
      wheelFull2: require('@/assets/img/wheelsFull/wheelsFull_2.png'),
      // eslint-disable-next-line global-require
      wheelFull3: require('@/assets/img/wheelsFull/wheelsFull_3.png'),
      // eslint-disable-next-line global-require
      wheelFull4: require('@/assets/img/wheelsFull/wheelsFull_4.png'),
      // eslint-disable-next-line global-require
      wheelFull5: require('@/assets/img/wheelsFull/wheelsFull_5.png'),
      // eslint-disable-next-line global-require
      wheelFull6: require('@/assets/img/wheelsFull/wheelsFull_6.png'),
      // eslint-disable-next-line global-require
      wheelFull7: require('@/assets/img/wheelsFull/wheelsFull_7.png'),
      // eslint-disable-next-line global-require
      wheelFull8: require('@/assets/img/wheelsFull/wheelsFull_8.png'),
      // eslint-disable-next-line global-require
      wheelFull9: require('@/assets/img/wheelsFull/wheelsFull_9.png'),
      // eslint-disable-next-line global-require
      wheelFull10: require('@/assets/img/wheelsFull/wheelsFull_10.png'),
      // eslint-disable-next-line global-require
      wheelFull11: require('@/assets/img/wheelsFull/wheelsFull_11.png'),
    };
  },
  computed: {
    scoringGaugeBaseClasses() {
      return 'scoring-gauge__points__row__score__meter';
    },
    hasData() {
      // console.log('this.data?.scoring', isNotEmpty(this.data?.scoring));
      return isNotEmpty(this.data?.scoring);
    },
    wheelValue() {
      if (isNotEmpty(this.data?.scoring?.scoringEquivalent)) {
        return Math.round(this.data.scoring.scoringEquivalent);
      }
      return 0;
    },
    wheelClasses() {
      const baseClasses = 'scoring-gauge__wheel';
      const val = this.wheelValue;
      if (val === 0) return `${baseClasses} active-quadrant--0`;
      if (val >= 0 && val < 10) return `${baseClasses} active-quadrant--1`;
      if (val >= 10 && val < 20) return `${baseClasses} active-quadrant--2`;
      if (val >= 20 && val < 30) return `${baseClasses} active-quadrant--3`;
      if (val >= 30 && val < 40) return `${baseClasses} active-quadrant--4`;
      if (val >= 40 && val < 50) return `${baseClasses} active-quadrant--5`;
      if (val >= 50 && val < 60) return `${baseClasses} active-quadrant--6`;
      if (val >= 60 && val < 70) return `${baseClasses} active-quadrant--7`;
      if (val >= 70 && val < 80) return `${baseClasses} active-quadrant--8`;
      if (val >= 80 && val < 90) return `${baseClasses} active-quadrant--9`;
      if (val >= 90 && val < 99) return `${baseClasses} active-quadrant--10`;
      if (val === 100) return `${baseClasses} active-quadrant--11`;
      return baseClasses;
    },
    scoringGaugeWheelLabel() {
      if (isNotEmpty(this.data?.scoring?.scoringLabel)) {
        return this.data.scoring.scoringLabel;
      }
      return this.notAvailableMsg;
    },
    scoringGaugeWheelPoints() {
      if (isNotEmpty(this.data?.scoring?.scoringEquivalent)) {
        return Math.round(this.data.scoring.scoringEquivalent);
      }
      return this.notAvailableMsg;
    },
    scoringGaugeCapitalisationLabel() {
      return 'PATRIMONIALIZZAZIONE';
    },
    scoringGaugeCapitalisationData() {
      if (isNotEmpty(this.data?.scoring?.capitalisationTotal?.point)) {
        return this.data.scoring.capitalisationTotal.point;
      }
      return this.notAvailableMsg;
    },
    scoringGaugeCapitalisationClasses() {
      return `${this.scoringGaugeBaseClasses} ${this.scoringGaugeExtraClasses(this.data?.scoring?.capitalisationTotal?.point)}`;
    },
    scoringGaugeProfitabilityLabel() {
      return 'REDDITIVITÀ';
    },
    scoringGaugeProfitabilityData() {
      if (isNotEmpty(this.data?.scoring?.profitabilityTotal.point)) {
        return this.data.scoring.profitabilityTotal.point;
      }
      return this.notAvailableMsg;
    },
    scoringGaugeProfitabilityClasses() {
      return `${this.scoringGaugeBaseClasses} ${this.scoringGaugeExtraClasses(this.data?.scoring?.profitabilityTotal?.point)}`;
    },
    scoringGaugeLiquidityLabel() {
      return 'LIQUIDITÀ';
    },
    scoringGaugeLiquidityData() {
      if (isNotEmpty(this.data?.scoring?.liquidityTotal?.point)) {
        return this.data.scoring.liquidityTotal.point;
      }
      return this.notAvailableMsg;
    },
    scoringGaugeLiquidityClasses() {
      return `${this.scoringGaugeBaseClasses} ${this.scoringGaugeExtraClasses(this.data?.scoring?.liquidityTotal?.point)}`;
    },
    scoringGaugeSectorLabel() {
      return 'SETTORE';
    },
    scoringGaugeSectorData() {
      if (isNotEmpty(this.data?.scoring?.sectorTotal?.point)) {
        return this.data.scoring.sectorTotal.point;
      }
      return this.notAvailableMsg;
    },
    scoringGaugeSectorClasses() {
      return `${this.scoringGaugeBaseClasses} ${this.scoringGaugeExtraClasses(this.data?.scoring?.sectorTotal?.point)}`;
    },
  },
  methods: {
    scoringGaugeExtraClasses(val) {
      if (val === 1) return 'is-step05';
      if (val === 2) return 'is-step10';
      if (val === 3) return 'is-step15';
      if (val === 4) return 'is-step20';
      if (val === 5) return 'is-step25';
      if (val === 6) return 'is-step30';
      if (val === 7) return 'is-step35';
      if (val === 8) return 'is-step40';
      if (val === 9) return 'is-step45';
      if (val === 10) return 'is-step50';
      if (val === 11) return 'is-step55';
      if (val === 12) return 'is-step60';
      if (val === 13) return 'is-step65';
      if (val === 14) return 'is-step70';
      if (val === 15) return 'is-step75';
      if (val === 16) return 'is-step80';
      if (val === 17) return 'is-step85';
      if (val === 18) return 'is-step90';
      if (val === 19) return 'is-step95';
      if (val >= 20) return 'is-step100';
      return '';
    },
  },
};
</script>

<style scoped>

</style>
